import consumer from "./consumer"

document.addEventListener('turbolinks:load', function() {
  //location.pathnameにchat_roomが含まれている場合のみ実行
  if(!location.pathname.includes('/chat_room/')) return;
  if(!$('#room-id').length) return;
  console.log("chat_room_channel.js");
  const channel = "ChatRoomChannel"
  const roomId = $('#room-id').val();
  const secret = $('#room-secret').val();
  const type   = "counselor";
  const userId = $('#user-id').val();
  const counselorId = $('#counselor-id').val();

  // 同じユーザーが2つ以上のチャンネルを同時に購読できないようにする
  // 他のトークルームはリアルタイムにならないだけで、更新すれば受信したものは見れる
  //const userIdentifier = `"room_id":"${roomId}"`;
  //const subscriptions = consumer.subscriptions.subscriptions;
  // console.log(subscriptions);
  // if(subscriptions){
  //   subscriptions.map(function userUnsubscribe(subscription){
  //     if(subscription.identifier.includes(userIdentifier)){
  //       subscription.consumer.subscriptions.remove(subscription)
  //     }
  //   });
  // }
  let userIdentifier = `"room_id":"${roomId}"`;
  let subscriptions = consumer.subscriptions.subscriptions;
  if(subscriptions){
    subscriptions.map(function userUnsubscribe(subscription){
      if(subscription.identifier.includes(userIdentifier)){
        subscription.consumer.subscriptions.remove(subscription)
      }
    });
  }

  const chatRoom = consumer.subscriptions.create(
      {
        // チャンネル情報とルーム情報の定義
        channel: channel, room_id: roomId, secret: secret, counselor_id: counselorId, user_id: userId, type: type
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
          console.log('salon channel connected');

          //再接続ボタンを非表示にする。
          $('.reconnect').css('display', 'none');
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
          console.log('salon channel disconnected');
          // トークルーム内で接続が切断された場合は、再接続ボタンを表示
          if( location.pathname.includes('/chat_room/') && $('#room-id').length ){
            $('.reconnect').css('display', 'block');
          }
        },

        // dataを受け取ったらチャット欄にリアルタイム表示
        received(data) {
          $.ajax({
            url: '/chat_room/get_message_partial',
            type: 'POST',
            data: {
              message_id: data['message_id'],
              room_id:    data['room_id'],
              secret:     data['secret'],
            }
          })
              .done(function(ret){
                // DOMにメッセージを追加して表示
                const messages = document.getElementById('messages');
                messages.insertAdjacentHTML('beforeend', ret['html']);

                // ページ下部まで自動スクロール
                $('html, body').animate(
                    { scrollTop: $('body').height() }, 'slow'
                );

                // メッセージタイプがmessage以外の場合、トークルームのパーシャルを更新する。
                if( ret['message_type'] !== 'message' ){
                  $.ajax({
                    url: '/chat_room/reload_reservation_info',
                    type: 'POST',
                    data: {
                      room_id: $('#room-id').val(),
                      secret:  $('#room-secret').val(),
                    }
                  })
                  // .done(function(){ console.log('reload reservation info success'); })
                  // .fail(function(){ console.log('reload reservation info failed'); });
                }
              });
        },

        // ユーザーからのチャット送信
        sendChatCounselor: function(message, roomId, secret) {
          return this.perform('send_chat_counselor',
              {
                message: message,
                room_id: roomId,
                secret: secret
              });
        }
      }
  );

  // 送信ボタンをクリックで送信
  $('#send-chat').on('click', function() {
    sendChat();
  });

  // テキストボックス上で、Ctrl+Enter OR Command+Enter で送信
  $('#send-chat-text').on('keydown', function(event) {
    // テキストの変換中なら何もしない
    if (event.originalEvent.isComposing) {
      return;
    }
    if( (event.ctrlKey |　event.metaKey) && event.key == 'Enter') {
      sendChat();
      event.preventDefault();
    }
  });

  // チャット送信
  function sendChat(){
    const message = $('#send-chat-text').val();
    if(message.trim()!=""){
      chatRoom.sendChatCounselor(message, roomId, secret);
      $('#send-chat-text').val('');
    }
  }

  // コンテキストメニューの作成
  $('.text-wrapper').on('contextmenu', function(event) {
    event.preventDefault(); // デフォルトのコンテキストメニューをキャンセル
    let number = this.dataset.number; // data-number 属性の値を取得
    console.log('data-number:', number);
    return false;
  });

  // 接続操作用カスタムイベントを定義
  $("#event-caller-ws-disconnect").on('click', function(e){
    $("#messages").trigger('wsDisconnect');
  });
  $("#event-caller-ws-connect").on('click', function(e){
    $("#messages").trigger('wsConnect');
  });
  $("#messages").on('wsDisconnect', function(e){
    chatRoom.consumer.disconnect();
    console.log('wsDisconnect');
  });
  $("#messages").on('wsConnect', function(e){
    chatRoom.consumer.connect();
    console.log('wsConnect');
  });

});
